<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>

    export default {
      mounted() {
        let systemLanguage = sessionStorage.getItem('systemLanguage');
        if (systemLanguage){
          this.$i18n.locale = systemLanguage
        }
      }
    }
    // 检查是否登录,没有登录跳转到sso

    // 登录路由到 botIndex

</script>

<style lang="less">
    @import "./assets/less/main/app.less";
    .commitLoadMore{
      min-width: 110px!important;
      padding: 8px 0!important;
      .popoverItem{
        display: flex;
        align-items: center;
        height: 36px;
        cursor:pointer;
        padding: 0 12px;
        i{
          margin-right: 10px;
          font-size: 14px;
        }
      }
      .popoverItem:hover{
        background: #F5F8FF;
      }
    }
    .regular-pop {
        z-index: 999999 !important;
    }

    .ck-widget {
        margin-left: 4px;
        margin-bottom: 4px;
        display: inline-block;
    }

    // ::-moz-selection { background-color: red;}
    // ::selection { background-color: red; }

    .el-radio{
      padding: 4px 0;
    }
    .send-range-item{
        color: #366AFF!important;
    }
    .changeChannel{
        .el-button--primary{
            background-color: #366AFF!important;
            border-color: #366AFF!important;
        }

    }
    .ck .ck-balloon-panel{
      position: absolute!important;
    }
    .el-cascader__dropdown, .el-select-dropdown,.el-message-box__wrapper,.el-tooltip__popper {
        z-index: 9999999999 !important;
    }
    .el-message{
        z-index: 99999999999 !important;
        .el-message__content {
            max-height: 100px !important;
            overflow-y: auto !important;
            min-height: 16px;
            line-height: normal;
        }
    }
    .el-picker-panel {
        z-index: 99999999999 !important;
    }
    .el-drawer__wrapper {
        background-color: rgba(0, 0, 0, 0.2) !important;
    }
    .task_cascaderIntent .el-cascader-menu__wrap {
        height: 204px !important;
    }
    .ask-component-placeholder {
        white-space: pre-wrap;
    }

    

    .ask-self-switch-outer {
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        height: 39px;
        line-height: 39px;
        align-items: center;

        .ask-self-switch {
            .ask-self-switch-pre {
                margin-right: 4px;
                color: #616161;
            }

            .ask-self-switch-after {
                margin-left: 10px;
                color: #a9b3c6;
                font-size: 13px;
                margin-right: 8px;
            }
        }

        .icon-switch-box-true {
            display: none;
            height: 24px;
            background: #366AFF;
            border-radius: 12px;
            padding: 0 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            color: #fff;
            font-size: 16px;

            div {
                width: 14px;
                height: 14px;
                background: #FFFFFF;
                box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.18);
                border-radius: 50%;
                margin-left: 5px;
            }
        }

        .icon-switch-box-false {
            display: none;
            height: 24px;
            background: #366AFF;
            border-radius: 12px;
            padding: 0 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            background-color: rgb(226, 226, 226);
            color: #6E6B7B;

            div {
                width: 14px;
                height: 14px;
                background: #FFFFFF;
                box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.18);
                border-radius: 50%;
                margin-right: 5px;
            }
        }
    }
</style>
