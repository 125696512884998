const RP_Visible = (id) => {
    let rolePrivileges = localStorage.getItem("rolePrivileges") || [];
    // let flag = false;
    // if (id === 'DOCUMENT_EXTRACTION') {
    //     console.log(process.env,'process.env');
    //     if (process.env.VUE_APP_CONFIG_ENV === "production") {
    //         let mainIdList =
    //             ['137e3903781f45cb8f6d5c4a1e17ac9c',
    //                 '86dc09da570948d2b4841122d732f373',
    //                 'eb4dd77629214ed985f17174df9676bd',
    //                 '1419b09cb2ce11ea8f0900163e0964d5',
    //                 '90df4764122240de939331d372546c28',
    //                 '50a9fb43e7084fdfa450e528c8770d4e',
    //                 '2fcdafab8cdd483586bfb117dc9d15af',
    //                 'e18906a23a3240dd83ed8efd4929f1ef',
    //                 '04813c0bb2ce11ea8f0900163e0964d5',
    //                 'f1d7e06e9c51478e89bfab64de52c0e7',
    //                 '26f4714d1c2b46a89bb5c9c5fe8b8ea8',
    //                 'f0e8f6ff56074926999188f4fd5b03db',
    //                 '4865052ea5f648e8967b280a7fc9f211',
    //                 '56bec4b273ca4534b8c6d05479908e81',
    //                 '3231367f22c242bbaacea53626bf6c97',
    //                 'a48b9d3a83d34dab8cd901b85120ef8f'
    //             ];
    //         let mainId = localStorage.getItem('_mainId');
    //         if (mainIdList.indexOf(mainId) != -1) {
    //             flag = true;
    //         } else {
    //             flag = false;
    //         }
    //     } else { 
    //         flag = rolePrivileges.indexOf(id) != -1;
    //     }
    // } else { 
    //     flag = rolePrivileges.indexOf(id) != -1;
    // }
    return rolePrivileges.indexOf(id) != -1;
}
const ShowSide = () => {
    let mainId = localStorage.getItem('_mainId')
    console.log('mainId', mainId)
    let bool = false
    if (mainId === '90df4764122240de939331d372546c28') {
        bool = false
    }
    else {
        bool = true
    }
    console.log(bool)
    return bool
}
export { RP_Visible, ShowSide };
