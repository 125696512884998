import { axios } from '../axios';
import { isInnerIP } from './ip';
import { ssoCookieChanged, initSsoUid } from './cookie';

let doingReSetStorage = false;

function doReSetStorage (onSuccess, onException, token) {
  if (doingReSetStorage) {
    return;
  }
  doingReSetStorage = true;
  let url = '/api/sso/auth';
  if (token != null) {
    url = url + '?token=' + token;
  }
  axios.get(url)
    .then((res) => {
      localStorage.setItem("_uid", res.data.data.userId);
      localStorage.setItem("_mainId", res.data.data.mainId);
      localStorage.setItem("accountType", res.data.data.accountType);
      localStorage.setItem("roleId", res.data.data.roleId);
      localStorage.setItem("loginSuccessTimes", res.data.data.loginSuccessTimes);

      let rolePrivileges = [];
      res.data.data.rolePrivileges.forEach(item => {
        if (item.checked) {
          rolePrivileges.push(item.id);
        }
      })
      localStorage.setItem('rolePrivileges', JSON.stringify(rolePrivileges));
      initSsoUid();
      doingReSetStorage = false;
      if (onSuccess) {
        onSuccess(res);
      }
    }).catch((err) => {
      doingReSetStorage = false;
      console.info('do auth exception', err);
      if (onException) {
        onException(err);
      }
    });
}

function doCheckAndRestStorageInfo () {
  if (isInnerIP) {
    return;
  }
  if (ssoCookieChanged()) {
    doReSetStorage();
  }
}

export { doCheckAndRestStorageInfo, doReSetStorage }