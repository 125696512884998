import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { requestUrl } from './api/index';
import {apiKeys} from "./const/SpecialApiKey";
import { utils } from './utils/index';
import { RP_Visible,ShowSide } from './utils/rolePrivilegesVisible';
// import ElementUI from 'element-ui'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import VCharts from 'v-charts'
import router from './router'
import $ from 'jquery'

import CKEditor from '@ckeditor/ckeditor5-vue2';


// import 'element-ui/lib/theme-chalk/index.css'
// import './assets/font/fonticon.css' // 阿里云图标库引入
import './assets/style/theme/index.css' // element 自定义样式
import { FetchGet, FetchPost, FetchPut, FetchPut2, FetchPut3, FetchDelete, FetchDelete2, GetFullUrl, axios } from './axios'
import VueClipboard from 'vue-clipboard2'

import VueQuillEditor from 'vue-quill-editor'
import VueI18n from 'vue-i18n'
// import tinymce from 'tinymce'
// require styles

// 导入多语言的 JSON 文件
import enLocale from './locales/en.json';
import cnLocale from './locales/cn.json';

Vue.use(VueI18n);
// 创建 VueI18n 实例，并设置默认语言和多语言内容
const i18n = new VueI18n({
    locale: 'cn', // 默认语言
    messages: {
        en: enLocale, // 英文
        cn: cnLocale, // 中文
        // 其他语言...
    }
});
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import JSONView from 'vue-json-viewer'
Vue.use(JSONView)


import determineMainId from './utils/determineMainId'
Vue.prototype.determineMainId = determineMainId

// 初始化后端返回特殊时间格式  调用方式 new Date("2019-12-14T00:09:16.000+0000").Format("yyyy-MM-dd hh:mm:ss")
Date.prototype.Format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

! function() {
    function rem() {
        var width = document.documentElement.clientWidth || document.body.clientWidth;
        document.documentElement.style.fontSize = 1366 / 14.8 + 'px';
        //设置body字体大小，不影响body内字体大小
        document.body.style.fontSize = '12px';
        if(width<1366){
            document.documentElement.style.fontSize = 1366/14.8+'px';
        }
        /*alert(document.documentElement.clientWidth)*/
    }
    rem();
    window.addEventListener('resize', rem, false);
}();

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false;
Vue.prototype.FetchGet = FetchGet;
Vue.prototype.FetchPost = FetchPost;
Vue.prototype.FetchDelete = FetchDelete;
Vue.prototype.FetchDelete2 = FetchDelete2;
Vue.prototype.FetchPut = FetchPut;
Vue.prototype.FetchPut2 = FetchPut2;
Vue.prototype.FetchPut3 = FetchPut3;
Vue.prototype.GetFullUrl = GetFullUrl;
Vue.prototype.requestUrl = requestUrl;
Vue.prototype.utils = utils;
Vue.prototype.RP_Visible = RP_Visible;
Vue.prototype.showSide=ShowSide
Vue.prototype.apiKeys = apiKeys;
Vue.prototype._$ = $;
Vue.prototype.$eventBus = new Vue();
//引入Video.js
Vue.prototype.$video = Video;

// // 因为vant 跟 element 同时引入导致 $message.error 方法报错的问题，在此覆盖原方法！勿动
// import { Message, Notify } from 'element-ui';

// Message.prototype.error = (messages) => {
//     return Message({
//         type: 'error',
//         messages: messages
//     })
// }

// Message.prototype.warning = (messages) => {
//     return Message({
//         type: 'warning',
//         messages: messages
//     })
// }

// Message.prototype.success = (messages) => {
//     return Message({
//         type: 'success',
//         messages: messages
//     })
// }

// Vue.prototype.$message = Message
// Vue.prototype.$notify = Notify

// Notify

// Vue.use(ElementUI);
//引入图表组件V-charts
Vue.use(VCharts);
Vue.use(VueClipboard);
Vue.use(VueQuillEditor);
Vue.use(window.VueVideoPlayer);
// Vue.use(tinymce);

Vue.use(CKEditor);

Vue.directive('clickoutside', {
    bind(el, binding) {
        function handler(e) {
            if (el.contains(e.target)) {
                return false;
            }
            let isReturn = false
            !!binding.arg && binding.arg.map(item => {
                if (document.querySelector('#' + item).contains(e.target)) {
                    isReturn = true
                }
            })
            if (isReturn){
                return false
            }
            
            if (binding.expression) {
                binding.value(e);
            }
        }
        el._zClickOutside = handler;
        document.addEventListener('click', handler);
    },
    unbind(el) {
        // 解除事件监听
        document.removeEventListener('click', el._zClickOutside);
        delete el._zClickOutside;
    }
});

// 注册 v-copy 复制指令
Vue.directive('copy', {
    bind: function(el, binding) {
      el.$copy = function() {
        const textarea = document.createElement('textarea');
        textarea.value = binding.value;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('Copy');
        document.body.removeChild(textarea);
        Vue.prototype.$message.success("内容已复制到剪切板！");
      }
      el.addEventListener('click', el.$copy);
    },
    unbind: function(el) {
      el.removeEventListener('click', el.$copy);
    }
});


axios.interceptors.request.use(
    config => {
        let token = localStorage.getItem('token');
        config.headers.common.Authorization = 'Bearer ' + token;
        config.headers.common.mainId = localStorage.getItem('_mainId');
        config.headers.common.uid = localStorage.getItem('_uid');
        config.headers.common.requestSource = "OPEN";
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
Vue.prototype.$http = axios;

Vue.prototype.isE = () =>{
    if(window.location.hostname.includes('ebot.isheely.com') || window.location.hostname.includes('localhost')) {
        return true
    }  else {
        return false
    }
}

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        const local = window.location.hostname;
        if(window.location.origin == 'https://open.ebot.isheely.com' || window.location.origin.includes('localhost')) {
            document.title = to.meta.title.replace('Ask', 'e')
        } else if (local.indexOf('jd') !== -1) {
            document.title = to.meta.title.slice(0,to.meta.title.indexOf('- AskBot对话机器人平台'))
        }  else {
            document.title = to.meta.title
        }
    //   document.title = to.meta.title
    }
    next()
  })


new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
