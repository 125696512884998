const TEST_API_KEYS = {
    SAMPLE_BOT_API_KEY: '8f68f1c4f5c949b387e2ed32aa3fa6f4',
    REF_API_KEY1: '90c1211e95ec4e1091e5e10709bcc9db',
    REF_API_KEY2: '90c1211e95ec4e1091e5e10709bcc9db',
    REF_API_KEY3: '5a0cf1bb3baa40fb811c0ee319606129',
    REF_API_KEY4: '297f7771307940cbb9daa9f7c7ef2e42',
    REF_API_KEY5: '5a0cf1bb3baa40fb811c0ee319606129',
    REF_API_KEY6: '5a0cf1bb3baa40fb811c0ee319606129',
};

const PROD_API_KEYS = {
    SAMPLE_BOT_API_KEY: 'da1ed9b71ee74399a6da7a9e5b722306',
    REF_API_KEY1: 'ed1ee1d7c57145e98e2ee26d87d827bf',
    REF_API_KEY2: 'ed1ee1d7c57145e98e2ee26d87d827bf',
    REF_API_KEY3: 'ed1ee1d7c57145e98e2ee26d87d827bf',
    REF_API_KEY4: '73efdab9dc384122a0ced4a00adc3fd8',
    REF_API_KEY5: 'ed1ee1d7c57145e98e2ee26d87d827bf',
    REF_API_KEY6: 'ed1ee1d7c57145e98e2ee26d87d827bf',
};

const apiKeys = {
    test: TEST_API_KEYS,
    production: PROD_API_KEYS,
    test_production: TEST_API_KEYS,
    development: TEST_API_KEYS
}


const TEST_SKILL_IDS = {
    EX_REF_SKILL_ID: '251',
    EX_REF_SKILL_ID_01: '37',
    EX_REF_SKILL_ID_02: '37',
    EX_REF_SKILL_ID_03: '628',
    EX_REF_SKILL_ID_04: '631',
    EX_REF_SKILL_ID_05: '629',
    EX_REF_SKILL_ID_06: '630',
};

const PROD_SKILL_IDS = {
    EX_REF_SKILL_ID: '57',
    EX_REF_SKILL_ID_01: '31',
    EX_REF_SKILL_ID_02: '32',
    EX_REF_SKILL_ID_03: '34',
    EX_REF_SKILL_ID_04: '227',
    EX_REF_SKILL_ID_05: '33',
    EX_REF_SKILL_ID_06: '35',
}
const skillIds = {
    test: TEST_SKILL_IDS,
    production: PROD_SKILL_IDS,
    test_production: TEST_SKILL_IDS,
    development: TEST_SKILL_IDS
}

export {apiKeys, skillIds};
