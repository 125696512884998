export const checkUrlList = [
    "/api/channel/access/bind/bot",
    "/api/bind/dingtalk/group/template/bind",
    "/api/bind/dingtalk/bot/bind",
    "/api/bot/selectBotRelatedSkill",
    "/api/channel/access/knowledge/scope",
    "/api/intent/check/process/relation",
    "/api/intent/batch/sync/process",
    "/api/intent/tag/batch/ignore",
    "/api/statistics/config/question-type/",
    "/api/statistics/config/area/",
    "/api/intent/delete/",
    "api/record/train/search",
    "api/intent/tag/search",
    "api/intent/tag/trainingDetailsExport",
    "/api/epidemic-area/",
    "/bearing-api/data-template/data/page",
    "/api/bot/selectBotRelatedSkill/",
    "/api/statistics/config/question-consult/",
    "/api/statistics/data/",
    "/api/statistics/export/",
    "/api/documentExtract/addIntelligentExtractionTaskType/",
    "/api/documentExtract/createTask/",
    "/api/documentExtract/delTask/",
    "/api/documentExtract/getProgress/",
    "/api/documentExtract/addExtractingResult/",
    "/api/documentExtract/getProgressGroup/",
    "/api/bot/savePlatformLogoSet/",
    "/api/botHealthCheck/selectIntentDetailInBot/",
    "/api/intent/tag/getOverview/",
    "/api/record/train/summaryTrainingRecords/",
    "/api/statistics/config/depart-situation/",
    "/api/statistics/statisticsViewDisplayControl/",
    "/api/intent/image/add/",
    "/api/intent/image/change",
    "/api/intent/tag/recommendedIntent",
    "/knowledge-api/knowledge/getTemporaryCertificate/",
    "/knowledge-api/temporary-certificate/or-origin/",
    "knowledge-api/knowledge/htmlTextDeal/",
    "/api/tools/export/bot-knowledge/",
    "/api/intent/tag/intentMove/",
    "/api/channel/access/clientBindBot/",
    "/api/tools/export/bot-knowledge/",
    "/api/channel/access/unifySetGreeting/",
    "/api/check/startNode/relation/",
    "/api/intent/bots/tree/",
    "/api/intent/workOrderToKnowledge/train",
    "/api/intent/workOrderToKnowledge/train/remove",
    "/tag-api/chat-database/generateTicketQA",
    "/api/catalogue/save",
    "/api/mrc-task/save",
    "/api/mrc-task/list",
    "/api/mrc-task/move",
    "/api/catalogue/move",
    "/api/catalogue/move",
    "/api/mrc-task/batch-delete",
    "/knowledge-api/correction/extract-pdf-text",
    "/api/v1/entity/entityContentChange",
    "/api/v1/entity/dataInterconnectionOperate"
]

