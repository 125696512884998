import axios from "axios";
import Qs from "qs";

import { redirect2LoginPage } from "../const/redirectUri";
import { doCheckAndRestStorageInfo } from "../api/auth";
import { checkUrlList } from "../api/checkUrlList"

//配置axios 请求时长，及默认路径
if ("production" === process.env || "test_production" === process.env) {
    axios.defaults.baseURL = process.env.API_HOST;
}
axios.defaults.timeout = 30000;

console.log(
    "process.env.VUE_APP_EXPIRED_URL:",
    process.env,
    process.env.VUE_APP_EXPIRED_URL
);
// if (process.env.VUE_APP_BASE_API != '/api') {
//     axios.defaults.baseURL = process.env.VUE_APP_BASE_API
// } else {
//     axios.defaults.baseURL = 'http://localhost:8081/api/';
// }
// axios.defaults.withCredentials = true;
// axios.defaults.headers.post['Content-Type'] = 'application/json';

//axios  request拦截器
axios.interceptors.request.use(
    (config) => {
        doCheckAndRestStorageInfo();
        if (config.url.indexOf('/api/documentExtract/getTextForHtmlTag') !== -1) {
            axios.defaults.timeout = 20000;
        }
        if (config.method == "post") {
            let urlArrPra = config.url.split("?")[0].split("/");
            let lastUrlPra = urlArrPra[urlArrPra.length - 1];
            let filterArrPra = [
                "intent-keyword-rule",
                "entity",
                "types",
                "node",
                "access",
                "move",
                "webhook",
                "dir",
                "send",
                "oss",
                "test",
                "field",
                "form",
                "copy",
                "login-r",
                "change-pass",
                "web",
                "config",
                "operate",
                "group-send-message-task",
                "voice",
                "file",
                "video",
                "image",
                "text",
                "news",
                "link-news",
                "rule",
                "source-condition",
                "sort",
                "wechat-kf",
                "getIntentStatistical",
                "data",
                "export",
                "addIntelligentExtractionTaskType",
                "createTask",
                "delTask",
                "getProgress",
                "addExtractingResult",
                "getProgressGroup",
                "savePlatformLogoSet",
                "selectIntentDetailInBot",
                "textCheckWord",
                "link-news-CheckWord",
                "news-CheckWord",
                "getOverview",
                "summaryTrainingRecords",
                "depart-situation",
                "statisticsViewDisplayControl",
                "add",
                "change",
                "recommendedIntent",
                "getTemporaryCertificate",
                "or-origin",
                "htmlTextDeal",
                "bot-knowledge",
                "intentMove",
                "clientBindBot",
                "unifySetGreeting",
                "relation",
                "tree",
                "mark-batch",
                "catalogue",
                "mrc-task"
            ];
            if (
                filterArrPra.indexOf(lastUrlPra) < 0 &&
                !checkUrlList.some(url => { return config.url.includes(url) })
            ) {
                let urlArr = config.url.split("?")[0].split("/");
                let lastUrl = urlArr[urlArr.length - 1];
                let filterArr = ["batchuploadIntentQ"];
                if (filterArr.indexOf(lastUrl) < 0) {
                    config.data = Qs.stringify(config.data);
                }
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
//axios response 拦截器
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log("========error:", error.response);
        if (error.message === "Request failed with status code 403") {
            redirect2LoginPage();
        } else if (error.response && error.response.status == 510) {
            console.log("============== exe 510 action");
            // location.href = error.response.data.message;
            location.href = process.env.VUE_APP_EXPIRED_URL;
            return Promise.reject({ status: 510 });
        } else {
            return Promise.reject(error);
        }
    }
);

//get请求方法封装
function FetchGet (url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
                data: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
                console.log(err);
            });
    });
}

//post请求方法封装
function FetchPost (url, data = {}, config) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, config).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

//delete请求方法封装
function FetchDelete (url, params, data = {}, config) {
    console.log(data, 'datadatadata');
    return new Promise((resolve, reject) => {
        axios.delete(url + "/" + params, data, config).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

//delete2请求方法封装
function FetchDelete2 (url, params, data = {}, config) {
    console.log(data, 'data');
    return new Promise((resolve, reject) => {
        axios.delete(url, data, config).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

//delete请求方法封装 带请求体传参
function FetchDelete3 (url, params, data = {}, config) {
    return new Promise((resolve, reject) => {
        axios.delete(url + "/" + params, data, config).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

//put请求方法封装
function FetchPut (url, params, data = {}, config) {
    return new Promise((resolve, reject) => {
        axios.put(url + "/" + params, data, config).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

//put请求方法封装
function FetchPut2 (url, params, data = {}, config) {
    return new Promise((resolve, reject) => {
        axios.put(url, data, config).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

//put请求方法封装
function FetchPut3 (url, params, data = {}, config) {
    return new Promise((resolve, reject) => {
        axios.put(url, params, config).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

function GetFullUrl (url) {
    return axios.defaults.baseURL + url;
}

export {
    FetchGet,
    FetchPost,
    FetchDelete,
    FetchPut,
    FetchPut2,
    FetchPut3,
    FetchDelete2,
    GetFullUrl,
    axios,
};
