import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		activeKnow:{
			classifi:null,
			skill:null,
		}, // 意图-知识库 : 点击卡片跳转至详情页 , 再次返回知识库页面 , 希望定位的之前的分类标签以及知识库
	},
	mutations: {
		saveActiveKnow (state,payload) {
            state.activeKnow = payload;
        },
	},
	actions: {
	},
	modules: {
	}
})
